<template>
  <div v-click-outside="clickOutside" class="relative">
    <div
      class="cursor-pointer inline-flex items-center border-2 border-transparent"
      :class="{
        'bg-black/50 border-slate-100/20 py-3 px-4 border-2 outline-none focus:border-slate-200 focus:ring-0 transition-all disabled:text-slate-100': theme === 'input-normal',
        'bg-black/50 border-slate-100/20 py-0.5 px-2 border-2 outline-none focus:border-slate-200 focus:ring-0 transition-all disabled:text-slate-100 text-sm': theme === 'input-small',
        'border-slate-200': open,
        'w-full justify-between': full,
        'pointer-events-none': disabled,
        'bg-slate-900 border-slate-900': isTxModal,
        'rounded-lg': !prefixed,
        'rounded-r-lg': prefixed
      }"
      @click.prevent="toggleDropdown"
    >
      <div class="overflow-hidden text-ellipsis">
        <slot>
          <div class="flex items-center">
            <SvgIcon
              v-if="activeOption.icon"
              :icon="activeOption.icon"
              :width="iconSize"
              :height="iconSize"
              class="my-autom mr-2"
            />
            {{ activeOption.text }}
          </div>
        </slot>
      </div>

      <SvgIcon
        v-if="theme && (!selected || !clearable) && alternateArrow"
        icon="arrow-down"
        height="20"
        width="20"
        class="transition"
        :class="{ 'rotate-180': open }"
      />

      <span
        v-if="theme && (!selected || !clearable) && !alternateArrow"
        class="icon-ico-down-pointer ml-0.5"
        :class="{ 'rotate-180': open }"
      />
      <span
        v-if="theme && clearable && selected"
        class="icon-ico-edit text-xs ml-0.5"
      />
    </div>

    <ul
      v-if="open"
      class="py-2 max-h-60 overflow-y-auto overflow-x-hidden flex flex-col absolute z-20 shadow-2xl rounded-xl overflow-hidden"
      :class="[
        showOnLeft ? 'left-0' : 'right-0',
        revealOnTop ? 'bottom-full mb-[3px]' : 'top-full mt-[3px]',
        full ? 'w-full text-left' : '',
        isTxModal ? 'bg-slate-600' : 'bg-slate-900'
      ]"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        class="block w-full px-5 py-2 hover:bg-slate-900 text-gray-400 hover:text-white transition-all duration-100 text-sm cursor-pointer whitespace-nowrap"
        :class="{ 'text-white': activeOption?.text === option?.text }"
        @click.prevent="handleSelectOption(option)"
      >
        <div class="flex items-center">
          <SvgIcon
            v-if="option.icon"
            :icon="option.icon"
            :width="iconSize"
            :height="iconSize"
            class="my-autom mr-2"
          />
          {{ option.text }}
          <span
            v-if="option.badge"
            class="label px-2 py-0.5 rounded-md ml-2"
            :class="option.badge.classes"
          >
            {{ option.badge.name }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
const props = defineProps({
  options: {
    type: Array,
    default: null,
  },
  activeOption: {
    type: Object,
    default: null,
  },
  showOnLeft: {
    type: Boolean,
    default: false,
  },
  revealOnTop: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: null,
  },
  full: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  isTxModal: {
    type: Boolean,
    default: false,
  },
  prefixed: {
    type: Boolean,
    default: false,
  },
  alternateArrow: {
    type: Boolean,
    default: false,
  },
  iconSize: {
    type: String,
    default: '24',
  },
});

const emits = defineEmits([
  'select',
]);

const open = ref(false);
const selected = ref(null);

function clickOutside() {
  open.value = false;
}

function toggleDropdown() {
  if (selected.value && props.clearable) {
    selected.value = null;
    emits('select', null);
    open.value = false;
    return;
  }

  open.value = !open.value;
}

function handleSelectOption(option) {
  selected.value = option;
  emits('select', option);
  open.value = false;
}
</script>
